import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"

import { getCurrentLangKey } from 'ptz-i18n';
import styled from 'styled-components'

import Layout from "../components/layout"
import HeaderSection from '../components/Case/HeaderSection/HeaderSection'
import SEO from "../components/seo"

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`

const CasePage = props => {

  const url = props.location.pathname;
  const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
 
  let langKey = getCurrentLangKey(langs, defaultLangKey, url);
  let homeLink = `/${langKey}/`

  const casesdata = get(props, 'data', [])
  const seoData = get(props, 'data.caseData.nodes[0].seo', [])
  const pageData = get(props, 'data.pageData.nodes[0]', [])

  let dataObject = {
    title: seoData.title,
    description: seoData.description,
    lang: 'en',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.translatedUrl.nodes[0].slug,
    slugEn: props.data.pageData.nodes[0].slug,
  }

  return (
    <>
      <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
        <SEO {...dataObject} />
        <Container>
          <HeaderSection data={casesdata} url={homeLink} pageData={pageData}/>
        </Container>
      </Layout>
    </>
  )
}

export default CasePage

export const query = graphql`
{
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }
  tags: allCraftCasetypesTag(filter: {siteId: {eq:1}}) {
    nodes {
      title
    }
  }
  latest:allCraftCaseCaseEntry(sort: {order: DESC, fields: dateCreated}, limit: 3, filter: {siteId: {eq:1}}) {
      nodes {
        id
        uri
        slug
        title
        postDate
        casesubtitle  
        language      
        categoriesServices {
          ... on craft_serviceCategory_Category {
            title
          }
        }
        categoriesServices{
          title
        }
        casecardimg {
          url
        }
      }
    }
    translatedUrl:allCraftCasesCasesEntry(filter: {siteId: {eq:2}}) {
      nodes{
        slug
      }
    }
    pageData:allCraftCasesCasesEntry(filter: {siteId: {eq:1}}) {
      nodes{
        title
        pageIntro
        slug
      }
    }
    caseData:allCraftCasesCasesEntry(filter: {siteId: {eq:1}}) {
      nodes {
        title
        seo {
          title
          description
          social {
            facebook {
              image {
                url
              }
            }
            twitter {
              image {
                url
              }
            }
          }
          advanced {
            robots
          }
        }
      }
    }
    caseIntry:allCraftCaseCaseEntry(sort: {order: DESC, fields: dateCreated},filter: {siteId: {eq: 1}}) {
      nodes {
        id
        uri
        slug
        title
        casesubtitle  
        language      
        categoriesServices{
          title
        }
        categoriesServices {
          ... on craft_serviceCategory_Category {
            title
          }
        }
        casecardimg {
          url
        }
      }
    }
}
`
